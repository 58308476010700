import '../../styles/pages/spring-summer-2021/taste-of-boba-tea-success.scss';
import React from "react";
import Layout from '../../components/layout';
import { Helmet } from "react-helmet"
import ScrollAnimation from 'react-animate-on-scroll';
import MoreStories from "../../components/spring-summer-2021-stories/more-stories"
import Gallery from "react-photo-gallery";
import TextPDF from '../../components/spring-summer-2021-stories/text-pdf';

import socialBanner from "../../images/spring-summer-2021/social-banners/taste-of-boba-tea-success.jpg";


import bobaTop from "../../images/spring-summer-2021/taste-of-boba-tea-success/boba-top.svg"
import titleGraphic from "../../images/spring-summer-2021/taste-of-boba-tea-success/title-graphic.svg"
import gallery0 from '../../images/spring-summer-2021/taste-of-boba-tea-success/gallery/Tastea0.jpg';
import gallery1 from '../../images/spring-summer-2021/taste-of-boba-tea-success/gallery/Tastea1.jpg';
import gallery2 from '../../images/spring-summer-2021/taste-of-boba-tea-success/gallery/Tastea2.jpg';
import gallery3 from '../../images/spring-summer-2021/taste-of-boba-tea-success/gallery/Tastea3.jpg';
import gallery4 from '../../images/spring-summer-2021/taste-of-boba-tea-success/gallery/Tastea4.jpg';
import gallery5 from '../../images/spring-summer-2021/taste-of-boba-tea-success/gallery/Tastea5.jpg';
import gallery6 from '../../images/spring-summer-2021/taste-of-boba-tea-success/gallery/Tastea6.jpg';
import gallery7 from '../../images/spring-summer-2021/taste-of-boba-tea-success/gallery/Tastea7.jpg';
import gallery8 from '../../images/spring-summer-2021/taste-of-boba-tea-success/gallery/Tastea8.jpg';
import gallery9 from '../../images/spring-summer-2021/taste-of-boba-tea-success/gallery/Tastea9.jpg';
import vuNguyen from '../../images/spring-summer-2021/taste-of-boba-tea-success/Vu-Nguyen.jpg';

import bobaSet1 from '../../images/spring-summer-2021/taste-of-boba-tea-success/boba-set1.svg';
import bobaSet2 from '../../images/spring-summer-2021/taste-of-boba-tea-success/boba-set2.svg';
import bobaSet3 from '../../images/spring-summer-2021/taste-of-boba-tea-success/boba-set3.svg';
import bobaSet4 from '../../images/spring-summer-2021/taste-of-boba-tea-success/boba-set4.svg';


export default class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      moveTop1: 0,
      moveTop2: 0,
      moveTop3: 0,
      moveTop4: 0,
    };
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll(event) {

    var newMovement1 = window.pageYOffset / 30;
    var newMovement2 = window.pageYOffset / 10;
    var newMovement3 = window.pageYOffset / 60;
    var newMovement4 = window.pageYOffset / 20;

    this.setState({moveTop1: newMovement1});
    this.setState({moveTop2: newMovement2});
    this.setState({moveTop3: newMovement3});
    this.setState({moveTop4: newMovement4});


  }

  render() {
    var pageTitle = 'A Taste of Boba Tea Success';
    var pageDesc = 'While in college, two friends capitalized on a boba craze. Twenty years later, their Tastea franchise has expanded to 23 locations.';
    var slug = 'taste-of-boba-tea-success';

    console.log(this.state.scrolly);
    // console.log('hello')

    const photos = [
      {
        src: gallery0,
        width: 1878,
        height: 1342,
        alt: '',
        title: ''
      },
      {
        src: gallery1,
        width: 754,
        height: 960,
        alt: '',
        title: ''
      },
      {
        src: gallery2,
        width: 758,
        height: 966,
        alt: '',
        title: ''
      },
      {
        src: gallery3,
        width: 754,
        height: 962,
        alt: '',
        title: ''
      },
      {
        src: gallery4,
        width: 758,
        height: 960,
        alt: '',
        title: ''
      },
      {
        src: gallery5,
        width: 754,
        height: 962,
        alt: '',
        title: ''
      },
      {
        src: gallery6,
        width: 756,
        height: 962,
        alt: '',
        title: ''
      },
      {
        src: gallery7,
        width: 756,
        height: 931,
        alt: '',
        title: ''
      },
      {
        src: gallery8,
        width: 754,
        height: 960,
        alt: '',
        title: ''
      },
      {
        src: gallery9,
        width: 754,
        height: 958,
        alt: '',
        title: ''
      }
    ];

    return (
      <Layout headerStyle="white" locationInfo={this.props.location} issue="spring-summer-2021">
        <Helmet>
          <title>{pageTitle}</title>
          <body className={slug} />

          <meta name="description" content={pageDesc} />

          {/* Schema.org markup for Google+ */}
          <meta itemprop="name" content={pageTitle} />
          <meta itemprop="description" content={pageDesc} />
          <meta itemprop="image" content={socialBanner} />

          {/* Twitter Card data */}
          <meta name="twitter:title" content={pageTitle} />
          <meta name="twitter:description" content={pageDesc} />
          <meta name="twitter:image:src" content={socialBanner} />

          {/* Open Graph data */}
          <meta property="og:title" content={pageTitle} />
          <meta property="og:image" content={socialBanner} />
          <meta property="og:image:alt" content={pageTitle} />
          <meta property="og:description" content={pageDesc} />
        </Helmet>

        <div className="boba-container">

          <TextPDF slug={slug} />

          {/* right side */}
          <img src={bobaSet4} alt="" className="boba-balls boba-balls-seta" style={{transform: `translateY(-${this.state.moveTop1}px)`}} />
          <img src={bobaSet1} alt="" className="boba-balls boba-balls-set1" style={{transform: `translateY(-${this.state.moveTop2}px)`}} />
          <img src={bobaSet2} alt="" className="boba-balls boba-balls-set2" style={{transform: `translateY(-${this.state.moveTop4}px)`}} />
          <img src={bobaSet3} alt="" className="boba-balls boba-balls-set3" style={{transform: `translateY(-${this.state.moveTop3}px)`}} />
          <img src={bobaSet4} alt="" className="boba-balls boba-balls-set4" style={{transform: `translateY(-${this.state.moveTop2}px)`}} />

          {/* left side */}
          <img src={bobaSet4} alt="" className="boba-balls boba-balls-setb" style={{transform: `translateY(-${this.state.moveTop4}px)`}} />
          <img src={bobaSet1} alt="" className="boba-balls boba-balls-set5" style={{transform: `translateY(-${this.state.moveTop1}px)`}} />
          <img src={bobaSet2} alt="" className="boba-balls boba-balls-set6" style={{transform: `translateY(-${this.state.moveTop2}px)`}} />
          <img src={bobaSet3} alt="" className="boba-balls boba-balls-set7" style={{transform: `translateY(-${this.state.moveTop3}px)`}} />
          <img src={bobaSet4} alt="" className="boba-balls boba-balls-set8" style={{transform: `translateY(-${this.state.moveTop2}px)`}} />
        
          <div className="boba-hero">
            <img src={vuNguyen} alt="Ted Vu and Scott Nguyen"  className="vu-nguyen animated zoomIn" />
            <span className="sub-title animated slideInRight">A couple of college students capitalized on a boba craze. Twenty years later, their Tastea franchise has expanded to 23 locations.</span>
            <h1 className="title-graphic animated slideInLeft"><img src={titleGraphic} alt="A Taste of Boba Tea Success" /></h1>
            <span className="byline">By Lynn Juliano / Photos courtesy of Ted Vu and Scott Nguyen</span>
            <img src={bobaTop} alt="" className="boba-top animated bounceInUp" />
          </div>
          <div className="container">
            <article className="wrap small story-content">
    
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p><span className="intro-text">While students at Cal State Fullerton in 2000,</span> Ted Vu and Scott Nguyen heard about a new boba tea craze hailing from Taiwan and making its way to Orange County.</p>
              </ScrollAnimation>
    
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p>Curious about the beverages filled with balls of tapioca, also known as bubble tea, the pair visited the first two stores that opened in Irvine: Tapioca Express and Lollicup. With lines snaked out the doors both day and night, Vu ’02 (B.A. business administration-finance) and Nguyen ’04 (B.F.A. art-entertainment art/animation) instantly saw an opportunity.</p>
              </ScrollAnimation>
    
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p>“Growing up in the U.S., we both were exposed to the creativity of Jamba Juice and the customer experience of Starbucks,” says Vu. “We decided at that moment, we would start a tea company focusing on a wide variety of creative drinks and delivering an exceptional customer experience.”</p>
              </ScrollAnimation>
    
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p>Friends since high school, the pair share how at 22 years old — with no business experience or financial credit — they wrote a business plan at Cal State Fullerton that opened the door to their first Tastea beverage store.</p>
              </ScrollAnimation>
              
            </article>
  
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <figure className="full-width gallery">
                <Gallery photos={photos}  />
              </figure>
              </ScrollAnimation>
  
            <article className="wrap small story-content">
              
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <h2>How did you launch your business?</h2>
              <p><strong>Vu:</strong> Aside from my high school job at Lamppost Pizza, neither of us had any food service experience. We found a boba supplier and purchased some product to start testing recipes at home. We used our credit cards to fund the initial business expenses. We came up with a menu of tea and ice-blended drinks, which we named Specialteas, Slushy Freeze and Smoothies.</p>
              </ScrollAnimation>
    
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p>The next step was to test our recipes with paying customers. We posted our menus in corporate break rooms and started delivering drinks during lunch. The feedback we received was great. We also rented a booth at the annual Lunar New Year Festival. After selling drinks around the clock for three days, we knew we had a winner.</p>
              </ScrollAnimation>
    
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p>We immediately started looking for a location, but every landlord turned us down. At the same time, I was taking a business writing class at CSUF and one of the assignments was to write a business plan. We finally found a landlord who was so impressed by our business plan that he decided to give us a chance. However, no banks would approve us for a business loan so we took out private loans with our cars as collateral. With a lease secured and capital in hand, we opened our first location in Garden Grove in 2001.</p>
              </ScrollAnimation>
              
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <h2>How did you scale your business?</h2>
              <p><strong>Vu:</strong> We had grand ideas of having 15 stores in five years, but it was much harder to scale a business than we thought. We didn’t open our second store at The Block in Orange until 2007, just a few months before the Great Recession. That store was a huge financial loss for us and eventually closed. Luckily, our original store in Garden Grove was thriving and we were able to open a third store in Rowland Heights in 2011. We opened our first franchise in Chino Hills in 2015 and have since grown to 23 stores, with more scheduled to open in 2021.</p>
              </ScrollAnimation>
    
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p>More recently, we’ve partnered with a Vietnamese banh mi purveyor to create Bite Mi Asian Kitchen. We currently have four locations.</p>
              </ScrollAnimation>
  
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <h2>What innovations have helped you weather the pandemic?</h2>
              <p><strong>Vu:</strong> Two years ago, we started investing in an online ordering app that would integrate with our point-of-sale system, third-party delivery programs, loyalty and gift card systems. We were fortunate to launch this app in March 2020, when the pandemic hit. The app helped us deliver our products to customers in ways that felt most safe and comfortable to them. We also installed pickup windows at our stores so customers did not have to wait inside for their orders, and began applying tamper evident stickers to every online and delivery order.</p>
              </ScrollAnimation>
  
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <blockquote>
                <p>“Get out there and do it. Not all ideas will work, but you just need one that will.”</p>
                <cite>- Scott Nguyen '04, co-founder of Tastea</cite>
              </blockquote>
              </ScrollAnimation>
  
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <h2>How did Cal State Fullerton prepare you for this work?</h2>
              <p><strong>Vu:</strong> I learned to think like a big business. With that in mind, we’ve always invested in technology and systems. How quickly we were able to pivot during this pandemic is a direct result of the education we received at CSUF.</p>
              </ScrollAnimation>
    
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p><strong>Nguyen:</strong> Because of my background in art, I lean heavily toward the creative side of the company. I make decisions on how our products are visually presented and what we want customers to feel when they walk into our stores. What I learned at CSUF helps me lead my team of creatives: I’m able to understand their workflow and give guidance.</p>
              </ScrollAnimation>
  
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <h2>What advice do you have for aspiring entrepreneurs?</h2>
              <p><strong>Nguyen:</strong> Get out there and do it. Not all ideas will work, but you just need one that will. Also, surround yourself with people who are better at your craft than you are.</p>
              </ScrollAnimation>
    
              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              <p><strong>Vu:</strong> Learn as much as you can and never stop learning. We continue to look at what our competitors do better than us and how we can improve. It’s how Tastea has remained relevant with the times. <span className="end-mark" /></p>
              </ScrollAnimation>

              <ScrollAnimation animateOnce={true} animateIn="fadeIn">
              </ScrollAnimation>


              <p className="learn-more">
                Support the College of Business and Economics<br />
                <a href="https://campaign.fullerton.edu/business-economics" target="_blank" rel="noopener noreferrer">campaign.fullerton.edu/business-economics</a>
              </p>
    
            </article>
          </div>

        </div>

        <MoreStories slug={slug} />
      </Layout>
    );
  }
} 